import { useTranslation } from 'react-i18next';
export function useT(ns, keyPrefix) {
    return useTranslation(ns, { keyPrefix }).t;
}
export function useLocale() {
    return useTranslation().i18n.language;
}
export function useDirection() {
    return useTranslation().i18n.dir();
}
