import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Text from '../Text/Text';
const StyledInput = forwardRef(({ inputPrefix, inputClassName, rightAccessories, ...props }, ref) => {
    const disabledClasses = props.disabled
        ? 'disabled:cursor-not-allowed disabled:bg-gray-100'
        : '';
    return (<div className="flex">
        {inputPrefix && (<span className={twMerge('flex items-center text-base mt-2 bg-gray-200 border border-brown-900 border-r-0 px-3 pointer-events-none', inputClassName)}>
            {inputPrefix}
          </span>)}
        {rightAccessories ? (<div className="flex flex-grow mt-2 justify-between items-center border focus-within:border-green-900 focus-within:ring-green-900">
            <input ref={ref} className={twMerge('form-input px-3 py-1.5 text-base w-full flex-grow border-none outline-none focus:outline-none focus:ring-0', disabledClasses, inputClassName)} {...props}/>
            {rightAccessories}
          </div>) : (<input ref={ref} className={twMerge('form-input mt-2 px-3 py-1.5 text-base w-full border border-brown-900 focus:border-green-900 focus:ring-green-900', disabledClasses, inputClassName)} {...props}/>)}
      </div>);
});
StyledInput.displayName = 'StyledInput';
const InputWithError = forwardRef(({ error, caption, ...props }, ref) => (<>
    <StyledInput ref={ref} {...props}/>
    {error && <ErrorMessage className="mt-1.5" error={error}/>}
    {!error && caption && (<Text size="xs" as="span" className="mt-1.5">
        {caption}
      </Text>)}
  </>));
InputWithError.displayName = 'InputWithError';
export const Input = forwardRef(({ label, className, showRequiredStar = false, children, ...props }, ref) => {
    return (<div className={twMerge('flex content-start flex-col w-full', className)} data-testid="aslan-input">
        {label ? (<label className="flex content-start w-full flex-col">
            <span className={`text-sm ${showRequiredStar ? 'after:content-["*"]' : ''}`}>
              {label}
            </span>
            {children}
            <InputWithError ref={ref} {...props}/>
          </label>) : (<InputWithError ref={ref} {...props}/>)}
      </div>);
});
Input.displayName = 'Input';
export default Input;
