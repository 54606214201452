import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
export const RadioButtonBaseStyles = 'flex items-center justify-center border mt-2 px-4 py-2 text-base shadow-md bg-white hover:bg-gray-100 cursor-pointer';
export function RadioButton({ className, childrenClassName, option, }) {
    return (<RadioGroup.Option key={option?.title} value={option?.value} className={({ checked }) => twMerge(className, RadioButtonBaseStyles, checked
            ? 'focus-within:border-green-900 focus-within:ring-1 focus-within:ring-inset focus-within:ring-green-900'
            : 'border-white')} data-testid="aslan-radio-button">
      {({ checked }) => (<div className="flex w-full items-start">
          <div className={twMerge(checked
                ? 'bg-green-900 border-transparent'
                : 'bg-white border-gray-300', 'h-4 w-4 min-w-[1rem] rounded-full border flex items-center justify-center mt-3')}>
            <span className="rounded-full bg-white w-1.5 h-1.5"/>
          </div>
          <div className="flex w-full items-center p-2 pl-4 text-sm">
            <div className="flex flex-col w-full text-sm">
              <RadioGroup.Label as="p" className={twMerge('text-left', option?.details ? 'font-semibold' : 'font-normal')}>
                {option?.title}
              </RadioGroup.Label>
              <RadioGroup.Description as="div" className="flex flex-col w-full">
                <span>{option?.details}</span>
                {checked && (<div data-testid="aslan-option-children" className={twMerge('flex justify-between gap-2', childrenClassName)}>
                    {option?.children}
                  </div>)}
              </RadioGroup.Description>
            </div>
          </div>
        </div>)}
    </RadioGroup.Option>);
}
export default RadioButton;
