import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
export const ToastInner = ({ children, className, type = 'guidance', isFullWidth = false, onClick = () => { }, animationClassNames = '', allowClose = true, ...props }) => {
    const [visible, setVisibility] = useState(true);
    const baseStyles = 'flex items-center rounded text-sm px-4 py-1.5 pointer-events-auto';
    const colorVariants = {
        guidance: 'bg-pink-900 text-brown-900',
        notice: 'bg-green-900 text-brown-900',
        info: 'bg-blue-900 text-brown-900',
        alert: 'bg-red-900 text-white',
    };
    const buttonRingColorVariants = {
        guidance: 'hover:ring-brown-900 focus:ring-brown-900',
        notice: 'hover:ring-white focus:ring-white',
        info: 'hover:ring-white focus:ring-white',
        alert: 'hover:ring-white focus:ring-white',
    };
    if (!visible) {
        return <></>;
    }
    return (<div className={twMerge(`${baseStyles} ${colorVariants[type]} ${animationClassNames}`, `relative ${isFullWidth ? 'w-full' : ''}`, className)} data-testid="aslan-toast" {...props}>
      <div className="flex-wrap grow break-words p-1">{children}</div>
      {allowClose && (<button aria-label="close" className={`
          h-6 w-6 flex-none p-1 rounded
          hover:ring-1 hover:shadow-md
          focus:outline-none focus:ring-1
          ${buttonRingColorVariants[type]}
        `} onClick={(e) => {
                e.preventDefault();
                // If we are using animationClassNames, we will
                // use the animation to close the toaster with onClick
                if (!animationClassNames) {
                    setVisibility(false);
                }
                onClick();
            }} type="button">
          <XMarkIcon className="h-full w-full" aria-hidden="true"/>
        </button>)}
    </div>);
};
export default ToastInner;
