import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Text from '../Text/Text';
export const commonClasses = 'form-textarea mt-2 px-3 py-1.5 w-full resize-none text-base border-brown-900 focus:border-green-900 focus:ring-green-900';
const TextareaWithError = forwardRef(({ error, ...props }, ref) => {
    return (<>
      <textarea ref={ref} rows={2} {...props}/>
      {error && <ErrorMessage className="mt-1.5" error={error}/>}
    </>);
});
TextareaWithError.displayName = 'TextareaWithError';
const TextareaWithCaption = forwardRef(({ caption, ...props }, ref) => (<>
    <textarea ref={ref} rows={2} {...props}/>
    {caption && (<Text size="xs" as="span" className="text-gray-600">
        {caption}
      </Text>)}
  </>));
TextareaWithCaption.displayName = 'TextareaWithCaption';
const TextAreaWithDetails = forwardRef(({ error, ...props }, ref) => {
    return error ? (<TextareaWithError ref={ref} error={error} {...props}/>) : (<TextareaWithCaption ref={ref} {...props}/>);
});
TextAreaWithDetails.displayName = 'TextAreaWithDetails';
export const Textarea = forwardRef(({ className, inputClassName, error, label, showRequiredStar = false, children, ...props }, ref) => {
    return (<div className={twMerge('flex content-start flex-col w-full', className)}>
        {label ? (<label className="flex content-start w-full flex-col">
            <span className={`text-sm ${showRequiredStar ? 'after:content-["*"]' : ''}`}>
              {label}
            </span>
            {children}
            <TextAreaWithDetails ref={ref} className={twMerge(commonClasses, inputClassName)} {...props}/>
          </label>) : (<TextAreaWithDetails ref={ref} className={twMerge(commonClasses, inputClassName)} {...props}/>)}
      </div>);
});
Textarea.displayName = 'Textarea';
export default Textarea;
