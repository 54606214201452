import React from 'react';
import { twMerge } from 'tailwind-merge';
export const Checkbox = ({ id, name, label, className = '', containerClassName = 'items-center' }) => {
    return (<div className={twMerge(containerClassName, 'flex space-x-2')}>
      <input className={twMerge(className, 'rounded text-green-900 focus:ring-green-900')} type="checkbox" name={name} id={id} value="true"/>
      <label htmlFor={id} className="text-sm">
        {label}
      </label>
    </div>);
};
export default Checkbox;
