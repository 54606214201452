export const LeapRibbonLogo = () => (<div className="w-[32px] h-[25px]">
    <svg viewBox="0 0 172 177" className="w-full h-full" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="The Leap logo">
      <path fillRule="evenodd" clipRule="evenodd" d="M48.3493 96.6772C21.3935 96.6772 0 115.439 0 139.122C0 157.991 15.2963 176.389 41.2039 176.325C68.0955 176.325 78.942 161.35 85.36 142.096C86.9748 143.256 88.6041 144.442 90.2522 145.643C107.754 158.393 127.379 172.688 154.204 172.688V172.686H171.276V141.944L154.204 141.837V141.839C131.298 141.839 116.506 130.612 101.588 119.29C98.6711 117.077 95.7498 114.86 92.7622 112.722L119.076 7.62939e-06H86.0232L63.3247 98.4101C58.4289 97.187 53.395 96.6045 48.3493 96.6772ZM41.4392 146.524C35.5132 146.524 32.3042 143.165 32.3042 138.887H32.2186C32.2186 131.484 39.471 125.302 48.0926 125.302C50.9873 125.336 53.8648 125.753 56.65 126.542L56.4146 128.019C53.7832 139.871 47.3652 146.524 41.4392 146.524Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M48.3493 96.6772C21.3935 96.6772 0 115.439 0 139.122C0 157.991 15.2963 176.389 41.2039 176.325C68.0955 176.325 78.942 161.35 85.36 142.096C86.9748 143.256 88.6041 144.442 90.2522 145.643C107.754 158.393 127.379 172.688 154.204 172.688V172.686H171.276V141.944L154.204 141.837V141.839C131.298 141.839 116.506 130.612 101.588 119.29C98.6711 117.077 95.7498 114.86 92.7622 112.722L119.076 7.62939e-06H86.0232L63.3247 98.4101C58.4289 97.187 53.395 96.6045 48.3493 96.6772ZM41.4392 146.524C35.5132 146.524 32.3042 143.165 32.3042 138.887H32.2186C32.2186 131.484 39.471 125.302 48.0926 125.302C50.9873 125.336 53.8648 125.753 56.65 126.542L56.4146 128.019C53.7832 139.871 47.3652 146.524 41.4392 146.524Z" fill="url(#paint0_linear_0_474)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M48.3493 96.6772C21.3935 96.6772 0 115.439 0 139.122C0 157.991 15.2963 176.389 41.2039 176.325C68.0955 176.325 78.942 161.35 85.36 142.096C86.9748 143.256 88.6041 144.442 90.2522 145.643C107.754 158.393 127.379 172.688 154.204 172.688V172.686H171.276V141.944L154.204 141.837V141.839C131.298 141.839 116.506 130.612 101.588 119.29C98.6711 117.077 95.7498 114.86 92.7622 112.722L119.076 7.62939e-06H86.0232L63.3247 98.4101C58.4289 97.187 53.395 96.6045 48.3493 96.6772ZM41.4392 146.524C35.5132 146.524 32.3042 143.165 32.3042 138.887H32.2186C32.2186 131.484 39.471 125.302 48.0926 125.302C50.9873 125.336 53.8648 125.753 56.65 126.542L56.4146 128.019C53.7832 139.871 47.3652 146.524 41.4392 146.524Z" fill="url(#paint1_linear_0_474)"/>
      <defs>
        <linearGradient id="paint0_linear_0_474" x1="184.142" y1="87.7652" x2="-75.7688" y2="40.0418" gradientUnits="userSpaceOnUse">
          <stop stopColor="#52B448"/>
          <stop offset="0.155575" stopColor="#ECF956"/>
          <stop offset="0.297316" stopColor="#E3C6E4"/>
          <stop offset="0.449522" stopColor="#FB4827"/>
          <stop offset="0.594597" stopColor="#29A7FF"/>
          <stop offset="1" stopColor="#52B448"/>
        </linearGradient>
        <linearGradient id="paint1_linear_0_474" x1="184.142" y1="87.7652" x2="-75.7688" y2="40.0418" gradientUnits="userSpaceOnUse">
          <stop stopColor="#52B448"/>
          <stop offset="0.155575" stopColor="#ECF956"/>
          <stop offset="0.297316" stopColor="#E3C6E4"/>
          <stop offset="0.449522" stopColor="#FB4827"/>
          <stop offset="0.594597" stopColor="#29A7FF"/>
          <stop offset="1" stopColor="#52B448"/>
        </linearGradient>
      </defs>
    </svg>
  </div>);
