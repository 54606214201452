export const Spinner = ({ className, title = 'Loading...', }) => {
    return (<svg stroke="#000" viewBox="0 0 24 24" className={className} xmlns="http://www.w3.org/2000/svg" data-testid="aslan-spinner" role="img" aria-label={title}>
      <title>{title}</title>
      <defs>
        <linearGradient id="theleap-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#52B448"/>
          <stop offset="21.3%" stopColor="#29A7FF"/>
          <stop offset="42.07%" stopColor="#FB4827"/>
          <stop offset="60.53%" stopColor="#E3C6E4"/>
          <stop offset="78.8%" stopColor="#ECF956"/>
          <stop offset="100%" stopColor="#52B448"/>
        </linearGradient>
      </defs>
      <g className="animate-spinner origin-center">
        <circle cx="12" cy="12" r="9.5" fill="none" stroke="url(#theleap-gradient)" strokeWidth="3" strokeLinecap="round" className="animate-spinnerBar"/>
      </g>
    </svg>);
};
export default Spinner;
