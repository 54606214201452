import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';
import { twMerge } from 'tailwind-merge';
export const Dropdown = ({ options, label, name, className, optionsClassName, onChange, }) => {
    const [selected, setSelected] = useState(options[0]);
    return (<div className={className}>
      <input type="hidden" value={selected.value} name={name}/>
      <Listbox value={selected} onChange={(selectedOptionValue) => {
            if (typeof selectedOptionValue === 'string') {
                const newOption = options.find((option) => option.value === selectedOptionValue) ||
                    options[0];
                onChange(newOption);
                setSelected(newOption);
            }
            else {
                setSelected(selectedOptionValue);
                onChange(selectedOptionValue);
            }
        }} name={name}>
        {label && <Listbox.Label>{label}</Listbox.Label>}
        <div className="relative mt-1.5">
          <Listbox.Button className="relative w-full cursor-default bg-white py-2 pl-3 pr-10 text-left border border-brown-900 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.text}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className={twMerge('absolute mt-1 w-full max-h-[270px] z-40 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm', optionsClassName)}>
              {options.map((option) => (<Listbox.Option key={option.key} className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`} value={option.value}>
                  {({ selected }) => (<>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {option.text}
                      </span>
                      {selected ? (<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                        </span>) : null}
                    </>)}
                </Listbox.Option>))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>);
};
export default Dropdown;
