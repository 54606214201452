import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
export const ALLOWED_TAGS_TEXT = [
    'b',
    'i',
    'strong',
    'em',
    'del',
    'mark',
    'p',
    'u',
    'br',
    'small',
];
export const ALLOWED_TAGS_FULL = [
    ...ALLOWED_TAGS_TEXT,
    'h2',
    'h3',
    'h4',
    'pre',
    'blockquote',
    'code',
    'ul',
    'ol',
    'li',
    'hr',
];
const ALLOWED_TAGS_BY_MODE = {
    text: ALLOWED_TAGS_TEXT,
    full: ALLOWED_TAGS_FULL,
};
export const purify = (html, mode) => ({
    __html: DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ALLOWED_TAGS_BY_MODE[mode],
    }),
});
const addStylesToHTML = (html, style) => {
    const el = document.createElement('div');
    el.innerHTML = html;
    Array.from(el.children).forEach((child) => {
        // Parse existing styles from the element
        const existingStyles = (child.getAttribute('style') || '') // Provide a default empty string if null
            .split(';')
            .reduce((acc, styleRule) => {
            const [key, value] = styleRule.split(':');
            if (key && value) {
                acc[key.trim()] = value.trim();
            }
            return acc;
        }, {});
        // Merge with new styles, giving precedence to new styles
        const mergedStyles = { ...existingStyles, ...style };
        // Convert the merged styles object back to string
        const mergedStylesString = Object.entries(mergedStyles)
            .map(([key, value]) => `${key}: ${value}`)
            .join('; ');
        // Set the merged styles back to the element
        child.setAttribute('style', mergedStylesString);
    });
    return el.innerHTML;
};
export const getRichTextClassName = (className) => twMerge('prose prose-theleap prose-sm focus:outline-none', 'prose-headings:font-serif prose-headings:font-normal', 'prose-h1:text-3xl prose-h2:text-2xl prose-h3:text-xl prose-h4:text-lg prose-p:text-sm', className);
export const UnsafeHTML = ({ mode, html, proseClasses, className, style, isThumbnail = false, }) => {
    const divClassName = proseClasses
        ? proseClasses
        : getRichTextClassName(className);
    const [finalHTML, setFinalHtml] = useState({
        __html: html,
    });
    useEffect(() => {
        const purifiedHtml = typeof document !== 'undefined' ? purify(html, mode) : { __html: html };
        // converting links to Achor tags
        var exp = /(\b((https?|ftp|file):\/\/|(www))[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]*)/gi;
        const linkClassName = 'text-xs underline hover:no-underline max-w-full';
        const linkReplacement = isThumbnail
            ? `<span class='${linkClassName}'>$1</span>`
            : `<a class='${linkClassName}' href='$1' target='_blank' style="color: inherit;" rel='noreferrer'>$1</a>`;
        purifiedHtml['__html'] = purifiedHtml['__html'].replace(exp, linkReplacement);
        purifiedHtml['__html'] =
            typeof document !== 'undefined'
                ? addStylesToHTML(purifiedHtml['__html'], style || {})
                : purifiedHtml['__html'];
        setFinalHtml(purifiedHtml);
    }, [html, style]);
    // TODO: figure how to run purify on CF workers
    return <div className={divClassName} dangerouslySetInnerHTML={finalHTML}/>;
};
export default UnsafeHTML;
