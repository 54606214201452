import { twMerge } from 'tailwind-merge';
export const Heading = ({ as: Element, children, className, size = 'lg', noOfLines, ...props }) => {
    const sizeVariants = {
        xs: 'text-xs',
        sm: 'text-sm',
        base: 'text-base',
        lg: 'text-lg',
        xl: 'text-xl',
        '2xl': 'text-2xl',
        '3xl': 'text-3xl',
        '4xl': 'text-4xl',
        '5xl': 'text-5xl',
        '6xl': 'text-6xl',
    };
    const noOfLinesVariants = {
        1: 'line-clamp-1',
        2: 'line-clamp-2',
        3: 'line-clamp-3',
        4: 'line-clamp-4',
        5: 'line-clamp-5',
        6: 'line-clamp-6',
    };
    return (<Element className={twMerge(`${sizeVariants[size]} ${noOfLines ? `${noOfLinesVariants[noOfLines]} text-ellipsis` : ''}`, className)} data-testid="aslan-heading" {...props}>
      {children}
    </Element>);
};
export default Heading;
