import { RadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { RadioButton } from './RadioButton';
export function RadioButtonGroup({ className, optionClassName, options, selectedValue, defaultValue, showRequiredStar = false, label, name, onChange = () => { }, }) {
    return (<RadioGroup className={twMerge('space-y-2', className)} value={selectedValue} defaultValue={defaultValue} onChange={onChange} name={name}>
      {label && (<RadioGroup.Label className={`text-sm ${showRequiredStar ? 'after:content-["*"]' : ''}`}>
          {label}
        </RadioGroup.Label>)}
      {options.map((option, index) => (<RadioButton className={optionClassName} key={`option-${name}-${index}`} option={option}/>))}
    </RadioGroup>);
}
export default RadioButtonGroup;
