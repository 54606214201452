import { twMerge } from 'tailwind-merge';
export const TheLeapLogo = ({ className }) => (<svg className={twMerge('w-full h-full', 'fill-text-primary', className)} viewBox="0 0 136 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M96.0562 4.09852H86.541V8.6368H95.2143V12.0883H86.5385V16.4952H96.0562V19.9395H82.6084V0.654205H96.0562V4.09852Z"/>
    <path d="M113.931 19.9395L112.303 15.2555H103.041L101.411 19.9395H97.3442L104.304 0.654205H111.04L118.003 19.9395H113.931ZM107.672 2.3907L104.248 11.8112H111.099L107.672 2.3907Z"/>
    <path d="M128.639 0.654205C132.876 0.654205 135.375 3.07861 135.375 6.68773C135.375 10.4067 132.876 13.0795 128.639 13.0795H124.042V19.9395H120.104V0.654205H128.639ZM127.797 9.63521C130.491 9.63521 131.474 8.64396 131.474 6.68773C131.474 4.73149 130.257 4.09852 127.797 4.09852H124.042V9.63521H127.797Z"/>
    <path d="M60.999 16.1921C60.999 13.5479 63.4326 11.4531 66.4989 11.4531C67.0729 11.445 67.6455 11.5101 68.2023 11.6466L70.7844 0.65921H74.5442L71.5509 13.2446C73.6292 14.704 75.4252 16.4954 78.5401 16.4954V19.9397C75.2012 19.9397 72.8431 18.0289 70.7089 16.5241C69.9789 18.6738 68.7451 20.3458 65.686 20.3458C62.739 20.3529 60.999 18.2987 60.999 16.1921ZM64.6737 16.1657C64.6737 16.6435 65.0388 17.0186 65.7129 17.0186C66.387 17.0186 67.117 16.2757 67.4163 14.9524L67.4431 14.7876C67.1263 14.6994 66.7989 14.6529 66.4697 14.6491C65.4889 14.6491 64.664 15.3394 64.664 16.1657H64.6737Z"/>
    <path d="M5.75293 19.9371V4.09852H0V0.654205H15.4652V4.09852H9.68314V19.9371H5.75293Z"/>
    <path d="M34.7731 0.654282V19.9372H30.8454V12.031H21.666V19.9372H17.7383V0.654282H21.666V8.58672H30.8454V0.654282H34.7731Z"/>
    <path d="M52.212 4.0986H42.6896V8.63687H51.3602V12.0764H42.6896V16.4833H52.212V19.9276H38.7593V0.654282H52.212V4.0986Z"/>
  </svg>);
