import { twMerge } from 'tailwind-merge';
/**
 * The classes we need to assign to the parent element of ButtonInner component.
 */
export const getInnerButtonParentClasses = (className = '') => twMerge('group inline-flex focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed', className);
const colorClasses = {
    yellow: {
        background: 'bg-yellow-900',
        outlineBackground: 'bg-white',
        border: 'border-brown-900',
        outlineBorder: 'border-yellow-900',
        textColorClass: 'text-brown-900',
        hoverStyles: 'group-hover:shadow-md',
        focusStyles: 'group-focus:border-brown-900 group-focus:ring-brown-900',
        disabledStyles: 'group-disabled:shadow-none',
    },
    white: {
        background: 'bg-white',
        outlineBackground: 'bg-white',
        border: 'border-white',
        outlineBorder: 'border-brown-900',
        textColorClass: 'text-gray-900',
        hoverStyles: 'group-hover:shadow-md',
        focusStyles: 'group-focus:border-green-900 group-focus:ring-green-900',
        disabledStyles: 'group-disabled:shadow-none',
    },
    gray: {
        background: 'bg-gray-200',
        outlineBackground: 'bg-white',
        border: 'bg-gray-200',
        outlineBorder: 'border-gray-200',
        textColorClass: 'text-gray-900',
        hoverStyles: 'group-hover:bg-white',
        focusStyles: 'group-focus:border-green-900 group-focus:ring-green-900',
        disabledStyles: 'group-disabled:shadow-none group-disabled:bg-gray-200',
    },
    pink: {
        background: 'bg-pink-900',
        outlineBackground: 'bg-white',
        border: 'bg-pink-900',
        outlineBorder: 'border-pink-900',
        textColorClass: 'text-gray-900',
        hoverStyles: 'group-hover:shadow-md',
        focusStyles: 'group-focus:border-pink-900 group-focus:ring-pink-900',
        disabledStyles: 'group-disabled:shadow-none',
    },
    blue: {
        background: 'bg-none',
        outlineBackground: 'bg-none',
        border: 'border-none',
        outlineBorder: 'border-blue-900',
        textColorClass: 'text-blue-900',
        hoverStyles: 'group-hover:underline',
        focusStyles: 'group-focus:border-blue-900 group-focus:ring-blue-900',
        disabledStyles: 'group-disabled:shadow-none',
    },
    brown: {
        background: 'bg-none underline',
        outlineBackground: 'bg-white',
        border: 'border-none',
        outlineBorder: 'border-brown-900',
        textColorClass: 'text-brown-900',
        hoverStyles: 'group-hover:no-underline group-hover:shadow-none',
        focusStyles: 'group-focus:shadow-none group-focus:no-underline',
        disabledStyles: 'group-disabled:shadow-none',
    },
    red: {
        background: 'bg-red-900 text-white',
        outlineBackground: 'bg-white text-red-900',
        border: 'border-none',
        outlineBorder: 'border-red-900',
        textColorClass: '',
        hoverStyles: 'group-hover:shadow-md group-hover:bg-white group-hover:text-red-900',
        focusStyles: 'group-focus:border-red-900 group-focus:ring-red-900',
        disabledStyles: 'group-disabled:shadow-none',
    },
    gradient: {
        background: 'bg-gradient-blue-pink',
        outlineBackground: 'bg-none',
        border: 'border-none',
        outlineBorder: 'border-none',
        textColorClass: 'text-brown-900',
        hoverStyles: 'group-hover:shadow-md',
        focusStyles: 'group-focus:border-green-900 group-focus:ring-green-900',
        disabledStyles: 'group-disabled:shadow-none',
    },
    transparent: {
        background: 'bg-transparent',
        outlineBackground: 'bg-transparent',
        border: 'border-transparent',
        outlineBorder: 'border-transparent',
        textColorClass: 'text-brown-900',
        hoverStyles: 'group-hover:bg-gray-200',
        focusStyles: 'group-focus:border-green-900 group-focus:ring-green-900',
        disabledStyles: 'group-disabled:shadow-none',
    },
};
export const ButtonInner = ({ children, className, color, hasShadow = false, icon, isFullWidth, isOutline = false, isRounded = false, size = 'md', ...props }) => {
    const baseStyles = 'flex h-min items-center justify-center whitespace-nowrap focus-ring inset-0 transform transition border group-focus:ring-1 group-disabled:bg';
    const { background, outlineBackground, border, textColorClass, outlineBorder, hoverStyles, focusStyles, disabledStyles, } = colorClasses[color];
    const backgroundColor = isOutline ? outlineBackground : background;
    const borderColor = isOutline ? outlineBorder : border;
    const sizeVariants = {
        xs: 'text-xs px-2 py-1.5',
        sm: 'text-sm px-3 py-1.5',
        base: 'text-base px-3 py-1.5',
        md: 'text-lg px-3 py-1.5',
    };
    return (<div className={twMerge(baseStyles, sizeVariants[size], isFullWidth ? 'w-full' : '', isRounded ? 'rounded-full' : '', hasShadow ? 'shadow-blur' : '', textColorClass, backgroundColor, borderColor, hoverStyles, focusStyles, disabledStyles, className)} {...props} data-testid="aslan-button-inner">
      {icon && <>{icon}</>}
      {children}
    </div>);
};
export default ButtonInner;
