import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
const MODAL_POSITION_CONFIGS = {
    middle: {
        buttonClass: 'absolute top-0 right-0',
        contentClass: '',
        jsxConfig: (content) => (<div className="fixed inset-0 z-50 overflow-y-auto">
        <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="mx-auto max-w-xl shadow-lg bg-white">
              {content}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </div>),
    },
    bottom: {
        buttonClass: '',
        contentClass: '',
        jsxConfig: (content) => (<div className="md:min-h-full md:min-w-full md:flex md:items-center md:justify-center">
        <div className="fixed inset-x-0 bottom-0 flex max-w-full pt-12 md:items-center md:overflow-visible z-50">
          <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="translate-y-full md:opacity-0" enterTo="translate-y-0 md:opacity-100" leave="ease-in-out duration-300" leaveFrom="translate-y-0 md:opacity-100" leaveTo="translate-y-full md:opacity-0">
            <Dialog.Panel className="w-screen bg-white shadow-lg h-fit max-h-full flex flex-col">
              <div className="max-w-md mx-auto w-full md:max-w-xl">
                {content}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>),
    }
};
export const ModalContent = ({ position = 'bottom', title, children, footer, setOpen, }) => {
    const { buttonClass, contentClass } = MODAL_POSITION_CONFIGS[position] || {};
    return (<>
      <div className="relative flex items-start justify-between pt-5 px-5 gap-2">
        {title && (<Dialog.Title className="text-xl sm:text-3xl text-gray-900 font-serif">
            {title}
          </Dialog.Title>)}

        <button type="button" className={twMerge('bg-white p-2.5 text-brown-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-green-900 ml-auto', buttonClass)} onClick={() => setOpen(false)}>
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
        </button>
      </div>
      <div className={twMerge('py-3 px-6 overflow-y-auto', contentClass)}>
        {children}
      </div>
      {footer && <div className="mt-3 px-6 pb-6">{footer}</div>}
    </>);
};
export const Modal = ({ children, footer, open, setOpen, title, className, position = 'bottom', hasGradientBackdrop = false, initialFocus, }) => {
    const modalConfig = MODAL_POSITION_CONFIGS[position] || {};
    if (!modalConfig || !modalConfig.jsxConfig) {
        // Return null or some default UI if jsxConfig is not available
        return null;
    }
    const modalContent = (<ModalContent position={position} title={title} footer={footer} setOpen={setOpen}>
      {children}
    </ModalContent>);
    return (<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className={twMerge('relative', className)} onClose={setOpen} initialFocus={initialFocus}>
        <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className={twMerge(hasGradientBackdrop
            ? 'bg-gradient-theleap-diagonal'
            : 'bg-gray-500 bg-opacity-80', 'fixed inset-0 transition-opacity z-40')}/>
        </Transition.Child>
        {modalConfig.jsxConfig(modalContent)}
      </Dialog>
    </Transition.Root>);
};
export default Modal;
