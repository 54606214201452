export const AddButtonIcon = ({ className }) => (<svg width="30" height="23" viewBox="0 0 30 23" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_13005_5499)">
      <rect x="1" y="4.6875" width="24.5" height="13.125" rx="4" fill="white"/>
    </g>
    <path d="M6.808 14.08C5.288 14.08 4.608 13.32 4.608 12.04V8.4H5.688V11.88C5.688 12.76 6.048 13.12 6.768 13.12H6.848C7.568 13.12 7.928 12.76 7.928 11.88V8.4H9.008V12.04C9.008 13.32 8.328 14.08 6.808 14.08ZM11.7532 14H10.6732V8.4H13.2732C14.2332 8.4 14.9932 9.04 14.9932 9.92C14.9932 10.632 14.6812 11.096 14.0412 11.336C14.6492 11.336 14.9132 11.624 14.9132 12.128V14H13.8332V12.4C13.8332 12 13.7132 11.88 13.3132 11.88H11.7532V14ZM11.7532 9.36V10.936H12.9532C13.5932 10.936 13.9132 10.648 13.9132 10.128C13.9132 9.608 13.5932 9.36 12.9532 9.36H11.7532ZM20.7741 14H16.5341V8.4H17.6141V13.04H20.7741V14Z" fill="#4C2116"/>
    <path d="M25.5 12.125V8.6875C25.5 6.47836 23.7091 4.6875 21.5 4.6875H5C2.79086 4.6875 1 6.47836 1 8.6875V13.8125C1 16.0216 2.79086 17.8125 5 17.8125H18.5" stroke="#4C2116" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M24.625 17.375L22.875 21.75L19.375 12.125L24.1875 13.875L29 15.625L24.625 17.375ZM24.625 17.375L29 21.75" stroke="#4C2116" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
      <filter id="filter0_d_13005_5499" x="1" y="4.6875" width="26.5" height="17.125" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="2" dy="4"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13005_5499"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13005_5499" result="shape"/>
      </filter>
    </defs>
  </svg>);
