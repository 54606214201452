import { twMerge } from 'tailwind-merge';
import { ButtonInner, getInnerButtonParentClasses, } from '../ButtonInner/ButtonInner';
// This is a button that can be used as a regular button, <button> element
export const Button = ({ children, innerButtonClassName, innerButtonStyle, className, color, hasShadow, icon, isFullWidth, hasProgressState = false, isOutline, isRounded, size, ...buttonProps }) => {
    return (<button {...buttonProps} data-testid="aslan-button" className={getInnerButtonParentClasses(`${className || ''} ${isFullWidth ? 'w-full' : ''}`)}>
      <ButtonInner className={twMerge(innerButtonClassName, hasProgressState
            ? 'group-disabled:bg-gradient-theleap group-disabled:motion-safe:animate-background'
            : '')} color={color} hasShadow={hasShadow} icon={icon} isFullWidth={isFullWidth} isOutline={isOutline} isRounded={isRounded} size={size} style={innerButtonStyle}>
        {children}
      </ButtonInner>
    </button>);
};
export default Button;
