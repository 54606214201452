import { Switch } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
export const Toggle = ({ label, isEnabled, setEnabled, className, }) => {
    const baseStyles = 'flex items-center';
    return (<Switch.Group as="div" className={twMerge(`${baseStyles}`, className)}>
      <Switch checked={isEnabled} onChange={setEnabled} className={twMerge(isEnabled ? 'bg-green-900' : 'bg-gray-200', 
        // eslint-disable-next-line quotes
        'relative inline-flex h-7 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent outline outline-1 outline-brown-900 transition-colors duration-200 ease-in-out focus-visible:shadow-activeToggle')} data-testid="aslan-toggle-switch">
        <span aria-hidden="true" className={twMerge(isEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-brown-900 shadow ring-0 transition duration-200 ease-in-out')}/>
      </Switch>

      {label && (<Switch.Label as="span" className="ml-3 text-sm cursor-pointer" data-testid="aslan-toggle-label">
          <span className="font-medium text-gray-900">{label}</span>
        </Switch.Label>)}
    </Switch.Group>);
};
