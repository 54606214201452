// TODO: use translations for text
// once translations support html within the text variables
export const PhotoCredit = ({ artist, platform, }) => {
    return (<p className="absolute bottom-0 right-0 px-1 py-1 text-center text-2xs text-gray-100 bg-black/40">
      Photo by{' '}
      <a href={artist.url} className="underline" target="_blank" rel="noreferrer">
        {artist.name}
      </a>{' '}
      on{' '}
      <a href={platform.url} className="underline" target="_blank" rel="noreferrer">
        {platform.name}
      </a>
    </p>);
};
export default PhotoCredit;
