import { autoUpdate, useFloating, offset, flip, shift, } from '@floating-ui/react';
import { Popover, Transition } from '@headlessui/react';
import { cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';
export function PopoverGroup({ children, buttonClassName, buttonChildren, panelClassName, panelHasShadow, placement = 'bottom-end', }) {
    const { refs, floatingStyles } = useFloating({
        placement: placement,
        middleware: [offset(10), flip(), shift()],
        whileElementsMounted: autoUpdate,
    });
    return (<Popover className="relative">
      {({ open, close }) => (<>
          <Popover.Button ref={refs.setReference} className={twMerge('group focus-visible:outline-none', buttonClassName)}>
            {buttonChildren}
          </Popover.Button>
          <Transition as="div" show={open} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-0" enterTo="opacity-100 translate-y-1" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-1" leaveTo="opacity-0 translate-y-0" className="relative z-40">
            <Popover.Panel data-testid="popover-group-panel" ref={refs.setFloating} style={floatingStyles} className={twMerge('absolute w-max z-40', panelClassName, panelHasShadow ? 'shadow-blur' : '')}>
              {cloneElement(children, {
                close: close,
            })}
            </Popover.Panel>
          </Transition>
        </>)}
    </Popover>);
}
export default PopoverGroup;
