import { useEffect, useCallback } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { toast } from 'react-hot-toast';
import ToastInner from '../../components/ToastInner/ToastInner';
export const Toaster = ({ type, toastFunc, message }) => (<ToastInner className="max-w-md shadow-lg" animationClassNames={toastFunc?.visible ?? false ? 'animate-enter' : 'animate-leave'} type={type} isFullWidth onClick={() => toast.dismiss(toastFunc?.id)}>
    {message}
  </ToastInner>);
const renderToaster = (type, message, showCelebration) => 
// eslint-disable-next-line react/display-name
(toastFunc) => (<>
        {showCelebration && (<ConfettiExplosion force={1} duration={4000} particleCount={300} width={1600} colors={['#52B448', '#29A7FF', '#FB4827', '#E3C6E4', '#ECF956']}/>)}
        <Toaster type={type} toastFunc={toastFunc} message={message} showCelebration={showCelebration}/>
      </>);
export const useToaster = (toasterMessage) => {
    const boundRenderToaster = useCallback(renderToaster, []);
    useEffect(() => {
        if (!toasterMessage) {
            return;
        }
        const toastOptions = toasterMessage.options || {};
        if (toasterMessage.type == 'alert' && !toastOptions.duration) {
            toastOptions.duration = Infinity;
        }
        toast.custom(boundRenderToaster(toasterMessage.type, toasterMessage.message, toasterMessage.options?.showCelebration), toastOptions);
    }, [toasterMessage, boundRenderToaster]);
};
export const displayToaster = ({ type, message, options = {}, }) => {
    toast.custom(renderToaster(type, message), options);
};
