export const LeapBetaLogo = ({ className }) => (<div className={className}>
    <svg className="w-full h-full" viewBox="0 0 830 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="578.399" y="-9.15527e-05" width="167.818" height="71.0001" rx="4.64601" fill="#4D2114"/>
      <path d="M619.589 51.6364C625.763 51.6364 630.037 47.9019 630.037 43.061C630.037 38.6811 627.188 35.961 623.721 35.2695C626.95 34.3474 629.325 31.6272 629.325 27.7084C629.325 23.098 625.526 19.3636 619.114 19.3636H604.392V35.5V51.6364H619.589ZM610.803 24.8961H618.165C621.251 24.8961 622.914 26.187 622.914 28.7227C622.914 31.2584 621.251 32.5954 618.165 32.5954H610.803V24.8961ZM610.803 37.9435H618.639C621.726 37.9435 623.626 39.2344 623.626 42.0006C623.626 44.7669 621.726 46.1039 618.639 46.1039H610.803V37.9435Z" fill="white"/>
      <path d="M661.541 51.6364V46.1039H642.307V37.8513H658.216V32.3188H642.307V24.8961H661.066V19.3636H635.895V51.6364H661.541Z" fill="white"/>
      <path d="M683.442 51.6364V24.8961H694.982V19.3636H665.538V24.8961H677.03V51.6364H683.442Z" fill="white"/>
      <path d="M724.836 51.6364L712.014 19.3636H704.51L691.355 51.6364H698.099L701.091 44.0292H715.053L718.045 51.6364H724.836ZM708.072 26.2792L712.869 38.4967H703.276L708.072 26.2792Z" fill="white"/>
      <path d="M398.478 14.544H359.042V33.7076H394.989V48.2821H359.031V66.891H398.478V81.4352H342.742V-0.000183105H398.478V14.544Z" fill="#4D2114"/>
      <path d="M472.562 81.4352L465.815 61.6563H427.427L420.669 81.4352H403.815L432.662 -0.000183105H460.58L489.437 81.4352H472.562ZM446.621 7.33245L432.43 47.1122H460.822L446.621 7.33245Z" fill="#4D2114"/>
      <path d="M533.521 -0.000183105C551.081 -0.000183105 561.439 10.2373 561.439 25.4774C561.439 41.1815 551.081 52.4679 533.521 52.4679H514.468V81.4352H498.149V-0.000183105H533.521ZM530.031 37.9236C541.196 37.9236 545.271 33.7379 545.271 25.4774C545.271 17.2169 540.228 14.544 530.031 14.544H514.468V37.9236H530.031Z" fill="#4D2114"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M275.973 45.6001C263.265 45.6001 253.179 54.4456 253.179 65.611C253.179 74.5069 260.39 83.181 272.605 83.1508C285.283 83.1508 290.397 76.0905 293.422 67.0129C294.184 67.5598 294.952 68.1194 295.729 68.6854C303.98 74.6963 313.232 81.4361 325.88 81.4361V66.8919C315.081 66.8919 308.106 61.5991 301.073 56.2612C299.698 55.2177 298.321 54.1725 296.912 53.1647L309.318 0.020874H293.735L283.034 46.4171C280.726 45.8404 278.352 45.5658 275.973 45.6001ZM272.716 69.1008C269.922 69.1008 268.409 67.5173 268.409 65.5H268.368C268.368 62.0102 271.788 59.0953 275.852 59.0953C277.217 59.1114 278.574 59.3081 279.887 59.6803L279.776 60.3763C278.535 65.964 275.509 69.1008 272.716 69.1008ZM333.929 81.4354H325.88V66.8912L333.929 66.9417V81.4354Z" fill="#4D2114"/>
      <path d="M24.2064 81.4252V14.5441H0.362793V-0.00012207H64.4602V14.5441H40.4955V81.4252H24.2064Z" fill="#4D2114"/>
      <path d="M144.484 -9.15527e-05V81.4252H128.205V48.0402H90.1598V81.4252H73.8807V-9.15527e-05H90.1598V33.496H128.205V-9.15527e-05H144.484Z" fill="#4D2114"/>
      <path d="M216.76 14.5441H177.293V33.7077H213.23V48.2318H177.293V66.8407H216.76V81.3849H161.004V-9.15527e-05H216.76V14.5441Z" fill="#4D2114"/>
    </svg>
  </div>);
