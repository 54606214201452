import { initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
export async function initI18nClient(opts) {
    await i18next
        .use(initReactI18next)
        .use(LanguageDetector)
        .use(intervalPlural)
        .init({
        fallbackLng: 'en',
        ns: getInitialNamespaces(),
        detection: { order: ['htmlTag'], caches: [] },
        interpolation: { escapeValue: false },
        ...opts,
    });
    return i18next;
}
export async function initI18nServer(req, ctx, i18n, opts) {
    const instance = i18next.use(initReactI18next);
    let lng = await i18n.getLocale(req);
    let ns = i18n.getRouteNamespaces(ctx);
    await instance.init({
        fallbackLng: 'en',
        react: { useSuspense: false },
        lng,
        ns,
        interpolation: { escapeValue: false },
        ...opts,
    });
    return instance;
}
