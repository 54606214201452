export const LeapPrimaryLogo = ({ className }) => (<div className={className}>
    <svg className="w-full h-full" viewBox="0 0 122 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0015 32.176V5.74727H6.57947V0H31.9082V5.74727H22.4383V32.176H16.0015Z" fill="#482317"/>
      <path d="M63.5302 0V32.176H57.0974V18.9836H42.0636V32.176H35.6308V0H42.0636V13.2363H57.0974V0H63.5302Z" fill="#482317"/>
      <path d="M92.0926 5.74727H76.4968V13.32H90.6976V19.0593H76.4968V26.4128H92.0926V32.1601H70.06V0H92.0926V5.74727Z" fill="#482317"/>
      <path d="M57.4175 42.792H41.8336V50.3647H56.0384V56.1239H41.8297V63.4774H57.4175V69.2247H35.3929V37.0447H57.4175V42.792Z" fill="#482317"/>
      <path d="M86.691 69.2247L84.0247 61.4088H68.8553L66.185 69.2247H59.525L70.9239 37.0447H81.9561L93.359 69.2247H86.691ZM76.44 39.9422L70.8322 55.6616H82.0518L76.44 39.9422Z" fill="#482317"/>
      <path d="M110.78 37.0447C117.719 37.0447 121.812 41.0901 121.812 47.1124C121.812 53.318 117.719 57.7779 110.78 57.7779H103.251V69.2247H96.8023V37.0447H110.78ZM109.401 52.0306C113.813 52.0306 115.423 50.3766 115.423 47.1124C115.423 43.8482 113.43 42.792 109.401 42.792H103.251V52.0306H109.401Z" fill="#482317"/>
      <path d="M0 62.9718C0 58.5597 3.98563 55.0643 9.00752 55.0643C9.94756 55.0507 10.8854 55.1593 11.7975 55.3871L16.0262 37.0532H22.184L17.2817 58.0535C20.6854 60.4887 23.6268 63.478 28.7284 63.478V69.2252C23.2601 69.2252 19.3981 66.0367 15.9027 63.5258C14.707 67.1129 12.6863 69.9028 7.67632 69.9028C2.84972 69.9147 0 66.4871 0 62.9718ZM6.0183 62.9279C6.0183 63.7251 6.61615 64.3508 7.72017 64.3508C8.82419 64.3508 10.0199 63.1113 10.5101 60.9032L10.554 60.6282C10.0351 60.4811 9.49899 60.4034 8.9597 60.397C7.35349 60.397 6.00236 61.5489 6.00236 62.9279H6.0183Z" fill="#482317"/>
      <path d="M28.7278 69.2277H31.9083V63.5004L28.7278 63.4805V69.2277Z" fill="#482317"/>
      <path d="M50.7419 74.9583H47.4075C47.3147 74.9583 47.2258 74.9952 47.1603 75.061C47.0947 75.1268 47.0579 75.216 47.0579 75.309C47.0579 75.402 47.0947 75.4913 47.1603 75.557C47.2258 75.6228 47.3147 75.6598 47.4075 75.6598H48.7255V79.6495C48.7255 79.7425 48.7623 79.8318 48.8279 79.8975C48.8934 79.9633 48.9823 80.0003 49.075 80.0003C49.1678 80.0003 49.2567 79.9633 49.3222 79.8975C49.3878 79.8318 49.4246 79.7425 49.4246 79.6495V75.6598H50.7419C50.7878 75.6598 50.8333 75.6507 50.8757 75.6331C50.9181 75.6154 50.9566 75.5896 50.9891 75.557C51.0216 75.5245 51.0473 75.4858 51.0649 75.4432C51.0824 75.4007 51.0915 75.3551 51.0915 75.309C51.0915 75.263 51.0824 75.2173 51.0649 75.1748C51.0473 75.1322 51.0216 75.0936 50.9891 75.061C50.9566 75.0284 50.9181 75.0026 50.8757 74.985C50.8333 74.9673 50.7878 74.9583 50.7419 74.9583Z" fill="#482317"/>
      <path d="M57.1262 74.9583C57.0798 74.9582 57.0337 74.9672 56.9908 74.9848C56.9478 75.0023 56.9088 75.0282 56.8759 75.0608C56.8431 75.0934 56.817 75.1321 56.7993 75.1747C56.7815 75.2173 56.7725 75.2629 56.7726 75.309V77.1285H53.8175V75.309C53.8175 75.216 53.7802 75.1268 53.7139 75.061C53.6476 74.9952 53.5576 74.9583 53.4638 74.9583C53.37 74.9583 53.2801 74.9952 53.2137 75.061C53.1474 75.1268 53.1102 75.216 53.1102 75.309V79.6495C53.1102 79.7425 53.1474 79.8318 53.2137 79.8975C53.2801 79.9633 53.37 80.0003 53.4638 80.0003C53.5576 80.0003 53.6476 79.9633 53.7139 79.8975C53.7802 79.8318 53.8175 79.7425 53.8175 79.6495V77.83H56.7726V79.6495C56.7726 79.6956 56.7817 79.7412 56.7995 79.7837C56.8172 79.8263 56.8433 79.865 56.8761 79.8975C56.909 79.9301 56.948 79.9559 56.9909 79.9736C57.0338 79.9912 57.0798 80.0003 57.1262 80.0003C57.1727 80.0003 57.2187 79.9912 57.2616 79.9736C57.3045 79.9559 57.3435 79.9301 57.3763 79.8975C57.4092 79.865 57.4352 79.8263 57.453 79.7837C57.4708 79.7412 57.4799 79.6956 57.4799 79.6495V75.309C57.4799 75.263 57.4708 75.2173 57.453 75.1748C57.4352 75.1322 57.4092 75.0936 57.3763 75.061C57.3435 75.0284 57.3045 75.0026 57.2616 74.985C57.2187 74.9673 57.1727 74.9583 57.1262 74.9583Z" fill="#482317"/>
      <path d="M60.5033 74.9583C60.4591 74.9582 60.4154 74.9672 60.3746 74.9848C60.3337 75.0023 60.2966 75.0282 60.2654 75.0608C60.2342 75.0934 60.2094 75.1321 60.1926 75.1747C60.1757 75.2173 60.1671 75.2629 60.1672 75.309V79.6495C60.1672 79.7425 60.2026 79.8318 60.2656 79.8975C60.3286 79.9633 60.4141 80.0003 60.5033 80.0003C60.5924 80.0003 60.6779 79.9633 60.741 79.8975C60.804 79.8318 60.8394 79.7425 60.8394 79.6495V75.309C60.8394 75.216 60.804 75.1268 60.741 75.061C60.6779 74.9952 60.5924 74.9583 60.5033 74.9583Z" fill="#482317"/>
      <path d="M70.9236 74.9583C70.8345 74.9583 70.749 74.9952 70.6859 75.061C70.6229 75.1268 70.5875 75.216 70.5875 75.309V79.6495C70.5875 79.7425 70.6229 79.8318 70.6859 79.8975C70.749 79.9633 70.8345 80.0003 70.9236 80.0003C71.0128 80.0003 71.0983 79.9633 71.1613 79.8975C71.2243 79.8318 71.2598 79.7425 71.2598 79.6495V75.309C71.2599 75.2629 71.2512 75.2173 71.2344 75.1747C71.2175 75.1321 71.1928 75.0934 71.1615 75.0608C71.1303 75.0282 71.0932 75.0023 71.0524 74.9848C71.0115 74.9672 70.9678 74.9582 70.9236 74.9583Z" fill="#482317"/>
      <path d="M77.3114 74.9583C77.2222 74.9583 77.1367 74.9952 77.0737 75.061C77.0107 75.1268 76.9753 75.216 76.9753 75.309V79.6495C76.9753 79.7425 77.0107 79.8318 77.0737 79.8975C77.1367 79.9633 77.2222 80.0003 77.3114 80.0003C77.4005 80.0003 77.486 79.9633 77.5491 79.8975C77.6121 79.8318 77.6475 79.7425 77.6475 79.6495V75.309C77.6476 75.2629 77.639 75.2173 77.6221 75.1747C77.6053 75.1321 77.5805 75.0934 77.5493 75.0608C77.518 75.0282 77.481 75.0023 77.4401 74.9848C77.3993 74.9672 77.3556 74.9582 77.3114 74.9583Z" fill="#482317"/>
      <path d="M86.0509 74.9583C86.0068 74.9583 85.9631 74.9673 85.9223 74.985C85.8815 75.0026 85.8444 75.0284 85.8132 75.061C85.782 75.0936 85.7573 75.1322 85.7404 75.1748C85.7235 75.2173 85.7148 75.263 85.7148 75.309V79.6495C85.7148 79.7425 85.7502 79.8318 85.8132 79.8975C85.8763 79.9633 85.9618 80.0003 86.0509 80.0003C86.1401 80.0003 86.2256 79.9633 86.2886 79.8975C86.3516 79.8318 86.3871 79.7425 86.3871 79.6495V75.309C86.3871 75.2629 86.3785 75.2173 86.3617 75.1747C86.3448 75.1321 86.32 75.0934 86.2888 75.0608C86.2576 75.0282 86.2205 75.0023 86.1797 74.9848C86.1388 74.9672 86.0951 74.9582 86.0509 74.9583Z" fill="#482317"/>
      <path d="M83.3472 74.9583H80.6845C80.6386 74.9582 80.5931 74.9672 80.5507 74.9848C80.5083 75.0023 80.4697 75.0282 80.4373 75.0608C80.4048 75.0934 80.3791 75.1321 80.3616 75.1747C80.344 75.2173 80.3351 75.2629 80.3352 75.309V79.6495C80.3352 79.6956 80.3442 79.7412 80.3618 79.7837C80.3793 79.8263 80.405 79.865 80.4375 79.8975C80.4699 79.9301 80.5084 79.9559 80.5508 79.9736C80.5932 79.9912 80.6386 80.0003 80.6845 80.0003C80.7303 80.0003 80.7758 79.9912 80.8181 79.9736C80.8605 79.9559 80.899 79.9301 80.9314 79.8975C80.9639 79.865 80.9896 79.8263 81.0072 79.7837C81.0247 79.7412 81.0338 79.6956 81.0338 79.6495V77.933H83.0871C83.1798 77.933 83.2686 77.8961 83.3341 77.8303C83.3996 77.7645 83.4364 77.6753 83.4364 77.5823C83.4364 77.4892 83.3996 77.4 83.3341 77.3342C83.2686 77.2684 83.1798 77.2315 83.0871 77.2315H81.0338V75.6598H83.3472C83.4399 75.6598 83.5287 75.6228 83.5942 75.557C83.6597 75.4913 83.6965 75.402 83.6965 75.309C83.6965 75.216 83.6597 75.1268 83.5942 75.061C83.5287 74.9952 83.4399 74.9583 83.3472 74.9583Z" fill="#482317"/>
      <path d="M67.5449 74.9583C67.4984 74.9582 67.4523 74.9672 67.4094 74.9847C67.3664 75.0023 67.3274 75.0282 67.2945 75.0608C67.2617 75.0933 67.2356 75.132 67.2179 75.1746C67.2002 75.2172 67.1911 75.2629 67.1912 75.309V78.6832L64.1534 75.0836C64.1068 75.0286 64.0443 74.9891 63.9743 74.9704C63.9044 74.9517 63.8304 74.9548 63.7622 74.9793C63.6941 75.0037 63.6352 75.0482 63.5934 75.1069C63.5517 75.1656 63.5291 75.2356 63.5288 75.3075V79.6488C63.5288 79.7418 63.566 79.831 63.6324 79.8968C63.6987 79.9625 63.7887 79.9995 63.8825 79.9995C63.9763 79.9995 64.0662 79.9625 64.1325 79.8968C64.1989 79.831 64.2361 79.7418 64.2361 79.6488V76.2745L67.2732 79.8741C67.3197 79.9294 67.3822 79.9692 67.4523 79.9881C67.5224 80.0069 67.5966 80.0039 67.6649 79.9794C67.7332 79.955 67.7923 79.9103 67.8341 79.8514C67.8759 79.7925 67.8984 79.7223 67.8985 79.6503V75.309C67.8985 75.2629 67.8894 75.2173 67.8716 75.1748C67.8538 75.1322 67.8278 75.0935 67.7949 75.061C67.7621 75.0284 67.7231 75.0026 67.6802 74.9849C67.6373 74.9673 67.5913 74.9583 67.5449 74.9583Z" fill="#482317"/>
      <path d="M92.8346 78.7839C92.5584 79.0421 92.2064 79.218 91.8232 79.2893C91.4401 79.3605 91.0429 79.324 90.6819 79.1843C90.3209 79.0446 90.0124 78.808 89.7953 78.5045C89.5782 78.2009 89.4624 77.844 89.4624 77.4789C89.4624 77.1138 89.5782 76.7569 89.7953 76.4533C90.0124 76.1498 90.3209 75.9132 90.6819 75.7735C91.0429 75.6338 91.4401 75.5973 91.8232 75.6685C92.2064 75.7398 92.5584 75.9157 92.8346 76.1739C92.9033 76.2325 92.9935 76.2641 93.0864 76.2621C93.1794 76.2601 93.2679 76.2248 93.3337 76.1633C93.3994 76.1019 93.4373 76.0191 93.4394 75.9323C93.4415 75.8454 93.4077 75.7611 93.345 75.697C92.9677 75.3443 92.487 75.1041 91.9637 75.0068C91.4403 74.9094 90.8978 74.9593 90.4048 75.1501C89.9118 75.3408 89.4904 75.664 89.1939 76.0786C88.8975 76.4932 88.7392 76.9806 88.7392 77.4793C88.7392 77.9779 88.8975 78.4654 89.1939 78.88C89.4904 79.2945 89.9118 79.6177 90.4048 79.8085C90.8978 79.9992 91.4403 80.0491 91.9637 79.9518C92.487 79.8544 92.9677 79.6142 93.345 79.2616C93.4102 79.1978 93.4461 79.1128 93.4451 79.0246C93.444 78.9365 93.4061 78.8522 93.3395 78.7898C93.2728 78.7274 93.1827 78.6919 93.0883 78.6908C92.994 78.6897 92.9029 78.7231 92.8346 78.7839Z" fill="#482317"/>
      <path d="M74.4669 75.0316C74.4257 75.0034 74.3785 74.9826 74.3282 74.9705C74.2778 74.9584 74.2253 74.9552 74.1735 74.9611C74.1218 74.967 74.0719 74.9819 74.0266 75.0049C73.9814 75.0279 73.9418 75.0585 73.91 75.0951L72.0153 77.2671C71.9618 77.3286 71.9328 77.4039 71.9328 77.4815C71.9328 77.559 71.9618 77.6344 72.0153 77.6958L73.91 79.8679C73.9749 79.9399 74.0692 79.9865 74.1726 79.9977C74.2761 80.0088 74.3803 79.9836 74.4629 79.9275C74.5455 79.8713 74.5998 79.7888 74.6142 79.6975C74.6285 79.6063 74.6017 79.5138 74.5396 79.4399L72.8316 77.4815L74.5396 75.5238C74.6038 75.45 74.6322 75.3567 74.6186 75.2644C74.6049 75.1721 74.5504 75.0883 74.4669 75.0316Z" fill="#482317"/>
      <path d="M32.329 79.9512C32.2015 79.9512 32.1023 79.9185 32.0315 79.853C31.9653 79.7829 31.9323 79.6847 31.9323 79.5585V75.4C31.9323 75.2738 31.9653 75.178 32.0315 75.1125C32.1023 75.0424 32.2015 75.0073 32.329 75.0073H34.015C34.3597 75.0073 34.6526 75.0588 34.8934 75.1616C35.139 75.2645 35.3255 75.4117 35.453 75.6034C35.5853 75.7951 35.6514 76.0265 35.6514 76.2976C35.6514 76.5968 35.5617 76.8493 35.3822 77.055C35.2027 77.2607 34.9595 77.3986 34.6526 77.4687V77.3636C35.002 77.4103 35.2759 77.5412 35.4743 77.7563C35.6727 77.9666 35.7718 78.2425 35.7718 78.5837C35.7718 79.0232 35.6254 79.3621 35.3326 79.6006C35.0398 79.8343 34.6289 79.9512 34.1 79.9512H32.329ZM32.6407 79.3902H34.015C34.3692 79.3902 34.6313 79.3224 34.8013 79.1868C34.9713 79.0466 35.0563 78.8362 35.0563 78.5557C35.0563 78.2752 34.9713 78.0671 34.8013 77.9316C34.6313 77.796 34.3692 77.7282 34.015 77.7282H32.6407V79.3902ZM32.6407 77.1672H33.9016C34.2417 77.1672 34.4991 77.0994 34.6738 76.9638C34.8485 76.8283 34.9359 76.6296 34.9359 76.3678C34.9359 76.106 34.8485 75.9073 34.6738 75.7717C34.4991 75.6361 34.2417 75.5683 33.9016 75.5683H32.6407V77.1672Z" fill="#482317"/>
      <path d="M39.6466 80.0003C39.5285 80.0003 39.4364 79.9675 39.3703 79.9021C39.3089 79.8366 39.2782 79.7431 39.2782 79.6216V77.3986L39.4411 77.8614L37.6914 75.4561C37.6394 75.386 37.6158 75.3136 37.6205 75.2388C37.6253 75.1593 37.656 75.0938 37.7126 75.0424C37.774 74.9863 37.8543 74.9583 37.9535 74.9583C38.029 74.9583 38.0975 74.977 38.1589 75.0144C38.225 75.0518 38.2864 75.1102 38.3431 75.1897L39.7457 77.1181H39.5757L40.9784 75.1897C41.0398 75.1055 41.0988 75.0471 41.1555 75.0144C41.2121 74.977 41.283 74.9583 41.368 74.9583C41.4672 74.9583 41.5427 74.984 41.5947 75.0354C41.6513 75.0821 41.6797 75.1453 41.6797 75.2247C41.6844 75.2995 41.6561 75.379 41.5947 75.4632L39.8591 77.8614L40.0079 77.3986V79.6216C40.0079 79.874 39.8874 80.0003 39.6466 80.0003Z" fill="#482317"/>
    </svg>
  </div>);
