import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
export const TabItemComponent = ({ tab, onNavigate, }) => {
    return (<Tab as={Fragment}>
      {({ selected }) => {
            const commonClasses = 'text-brown-900 relative whitespace-nowrap py-4 px-2 focus:outline-none focus:before:shadow-md before:content-[""] before:block before:absolute before:bottom-0 before:left-0 before:right-0 before:rounded before:h-1.5';
            const activeClasses = 'before:border before:bg-pink-900';
            const inactiveClasses = 'hover:before:bg-gray-300 hover:before:border-gray-300';
            const disabledClasses = 'before:hidden opacity-50 text-gray-600 cursor-not-allowed hover:text-gray-600';
            const classes = twMerge(commonClasses, tab.tabItemClasses, selected ? activeClasses : inactiveClasses, selected ? tab.tabItemActiveClasses : '', tab.disabled ? disabledClasses : '');
            const handleKeyDown = (e) => {
                if (tab.link?.path &&
                    (e.key === 'Enter' || e.key === ' ') &&
                    onNavigate) {
                    onNavigate(tab.link.path);
                }
            };
            if (tab.link?.LinkComponent) {
                const LinkComponent = tab.link.LinkComponent;
                return (<LinkComponent to={tab.link.path} className={classes} onKeyDown={handleKeyDown}>
              {tab.label}
            </LinkComponent>);
            }
            else {
                return (<button className={classes} disabled={tab.disabled}>
              {tab.label}
            </button>);
            }
        }}
    </Tab>);
};
export const Tabs = ({ tabs, defaultIndex = 0, selectedIndex, tabPanelClassName = 'pt-2', tabListClassName, onChange, onNavigate, }) => {
    const handleTabChange = (index) => {
        if (onChange) {
            const label = tabs[index].label;
            onChange(index, label);
        }
    };
    return (<Tab.Group defaultIndex={defaultIndex} selectedIndex={selectedIndex} onChange={handleTabChange}>
      <Tab.List className={twMerge('-mb-px flex space-x-8', tabListClassName)} aria-label="Tabs">
        {tabs.map((tab, index) => (<TabItemComponent key={index} tab={tab} onNavigate={onNavigate}/>))}
      </Tab.List>

      <Tab.Panels className={tabPanelClassName}>
        {tabs.map((tab, index) => (<Tab.Panel key={index} className={({ selected }) => (selected ? 'block' : 'hidden')} unmount={false}>
            {tab.content}
          </Tab.Panel>))}
      </Tab.Panels>
    </Tab.Group>);
};
export default Tabs;
