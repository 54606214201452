import { twMerge } from 'tailwind-merge';
export const avatarBaseStyles = 'flex items-center justify-center object-cover shadow-blur';
export const Avatar = ({ className, color = 'black', isRounded = false, size = 'md', src, alt, name, ...props }) => {
    const colorVariants = {
        yellow: 'bg-yellow-900 text-brown-900',
        black: 'bg-black text-white',
        gray: 'bg-gray-200 text-brown-900',
    };
    const sizeVariants = {
        xs: 'w-8 h-8 text-sm',
        sm: 'w-16 h-16 text-xl',
        md: 'w-32 h-32 text-3xl',
        lg: 'w-40 h-40 text-4xl',
    };
    return (<>
      {src ? (<img alt={alt || 'Avatar Image'} className={twMerge(`${avatarBaseStyles} ${sizeVariants[size]} ${isRounded ? 'rounded-full' : ''}`, className)} src={src || ''}/>) : (<span className={twMerge(`${avatarBaseStyles} ${colorVariants[color]} ${sizeVariants[size]} ${isRounded ? 'rounded-full' : ''}`, className)} data-testid="aslan-avatar" {...props}>
          <span>{name ? name[0].toUpperCase() : ''}</span>
        </span>)}
    </>);
};
export default Avatar;
