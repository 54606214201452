import { twMerge } from 'tailwind-merge';
export const getCardParentClasses = (className = '') => twMerge('group focus:outline-none', className);
export const cardBaseStyles = 'flex flex-col p-5 border bg-white group-hover:shadow-lg group-focus:border-green-900 group-focus:ring-green-900 group-focus:ring-1 transition justify-between';
export const Card = ({ children, className, childrenClassName, grandChildrenClassName, footerClassName, imgAlt, imgSrc, imgClassName, footer, ...props }) => {
    return (<div className={twMerge(cardBaseStyles, className)} data-testid="aslan-card" {...props}>
      <div data-testid="aslan-card-children" className={twMerge('flex justify-between gap-2', childrenClassName)}>
        <div className={twMerge('flex flex-col justify-center content-start max-w-full w-full', grandChildrenClassName)}>
          {children}
        </div>
        {imgSrc && (<div className="max-w-[5rem] w-full h-20">
            <img className={twMerge('object-cover w-full h-full', imgClassName)} alt={imgAlt ?? ''} src={imgSrc}/>
          </div>)}
      </div>
      {footer && (<div data-testid="aslan-card-footer" className={twMerge('mt-4', footerClassName)}>
          {footer}
        </div>)}
    </div>);
};
export default Card;
