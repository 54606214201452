import { autoUpdate, useFloating, offset, flip, shift, arrow, FloatingArrow, } from '@floating-ui/react';
import { Transition } from '@headlessui/react';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
export function Tooltip({ children, startOpen, text, panelClassName, panelHasShadow = true, placement = 'top-start', }) {
    const [open, setOpen] = useState(false);
    const arrowRef = useRef(null);
    const { refs, floatingStyles, context } = useFloating({
        placement: placement,
        middleware: [
            offset(10),
            flip({
                mainAxis: false,
            }),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    });
    useEffect(() => {
        setOpen(startOpen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<div className="relative z-10">
      <div ref={refs.setReference}>{children}</div>
      <Transition as="div" show={open} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-0" enterTo="opacity-100 translate-y-1" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-1" leaveTo="opacity-0 translate-y-0">
        <div ref={refs.setFloating} style={floatingStyles} className={twMerge('w-max z-40 relative grid grid-cols-1 max-w-[260px] text-center gradient-outline after:animate-border after:rounded gradient-outline-blur-sticky before:animate-border', panelClassName, panelHasShadow ? 'shadow-blur' : '')}>
          <FloatingArrow ref={arrowRef} context={context} className="fill-white"/>
          <button type="button" className={twMerge('absolute top-0 right-0 p-1.5 text-brown-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-green-900 z-10')} onClick={() => setOpen(false)}>
            <span className="sr-only">Close tooltip</span>
            <XMarkIcon className="h-4 w-4" aria-hidden="true"/>
          </button>
          <div className="p-6 rounded bg-white">{text}</div>
        </div>
      </Transition>
    </div>);
}
export default Tooltip;
