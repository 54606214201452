import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
const StyledSelect = forwardRef(({ selectClassName, ...props }, ref) => {
    return (<select ref={ref} className={twMerge('mt-2 px-3 py-1.5 block w-full pr-10 text-sm leading-8 border-0 ring-1 ring-inset ring-brown-900 focus:ring-2 focus:ring-green-900', selectClassName)} {...props}/>);
});
StyledSelect.displayName = 'StyledSelect';
export const Select = forwardRef(({ label, error, showRequiredStar = false, className, selectClassName, ...props }, ref) => {
    const selectId = props.id ?? 'simple-select';
    const errorId = `${selectId}-error`;
    return (<div className={twMerge('flex flex-col w-full', className)}>
        {label && (<label htmlFor={selectId} className="flex content-start w-full flex-col">
            <span className={`text-sm ${showRequiredStar ? 'after:content-["*"] after:text-red-500' : ''}`}>
              {label}
            </span>
          </label>)}

        <StyledSelect ref={ref} selectClassName={selectClassName} id={selectId} {...props} aria-describedby={error ? errorId : undefined} aria-required={showRequiredStar ? true : undefined}/>
        {error && <ErrorMessage className="mt-1.5" error={error}/>}
      </div>);
});
Select.displayName = 'Select';
export default Select;
