import { Alignment, Fit, Layout, useRive, useStateMachineInput, } from '@rive-app/react-canvas';
export const LogoExtendedTwirl = ({ wiggle = false, root = '/', }) => {
    const { rive, RiveComponent } = useRive({
        src: `${root}animations/theleap.riv`,
        stateMachines: 'CenterSlidingWiggler',
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.CenterLeft,
        }),
    });
    useStateMachineInput(rive, 'CenterSlidingWiggler', 'Wiggle', wiggle);
    return (<div className="pt-8 px-2 w-96">
      <RiveComponent className="w-full h-24 sm:h-48"/>
    </div>);
};
